<template>
	<div class="emergencyprocessFormHeader puiformheader">
		<v-row dense>
			<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.emergencyprocess.statusdesc')" :value="getStatusdescValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.emergencyprocess.visitid')" :value="getVisitidValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.emergencyprocess.shipname')" :value="getShipnameValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'emergencyprocess-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'emergencyprocess'
		};
	},
	computed: {
		getVisitidValue() {
			return this.model && this.model.visitid ? this.model.visitid : '-';
		},
		getShipnameValue() {
			return this.model && this.model.shipname ? this.model.shipname : '-';
		},
		getStatusdescValue() {
			return this.model && this.model.statusdesc ? this.model.statusdesc : '-';
		}
	}
};
</script>
